import React from 'react'
import {
  CFormInput,
  CFormLabel,
  CCollapse,
  CFormSelect
} from '@coreui/react-pro'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import Select from 'react-select'
import { useFormContext } from 'react-hook-form'

interface FinancialProfileProps {
  loading: boolean,
  isVisible: boolean
}

const FinancialProfile: React.FC<FinancialProfileProps> = ({ isVisible, loading }) => {
  const { user } = useAppSelector((state: RootState) => state.users)
  const {
    annualIncome,
    netWorthExcludingResidence,
    capitalAllocation,
    allSectors,
    sectors
  } = user
  const {
    register
  } = useFormContext()

  const sectorsDefaultValues = sectors.map((val: any, i: number) => {
    const obj = {
      label: val.sectorName,
      value: val.sectorId
    }
    return obj
  })

  const sectorsOptions = allSectors.map((val: any, i: number) => {
    const obj = {
      label: val.name,
      value: val.sectorId
    }
    return obj
  })

  return (
    <div className='row mt-4' style={{ paddingLeft: 20 }}>
      <CCollapse visible={isVisible}>
        <div className='row mb-2'>
          <div className='col-md-3'>
            <CFormLabel>Occupation</CFormLabel>
            <CFormInput disabled={loading} {...register('occupation')} />
          </div>
          <div className='col-md-3'>
            <CFormLabel>Employer Name</CFormLabel>
            <CFormInput disabled={loading} {...register('employerName')} />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3'>
            <CFormLabel>Annual Income</CFormLabel>
            <CFormSelect
              disabled={loading}
              defaultValue={annualIncome}
              {...register('annualIncome')}
            >
              <option value="UNKNOWN">Unknown</option>
              <option value="LESS_THAN_100K">$0-$99,000</option>
              <option value="BETWEEN_200K_500K">$100,000-$199,999</option>
              <option value="BETWEEN_100K_200K">$200,000-$499,999</option>
              <option value="BETWEEN_500K_1M">$500,000-$999,999</option>
              <option value="OVER_1M">$1,000,000+</option>
            </CFormSelect>
          </div>
          <div className='col-md-3'>
            <CFormLabel>Net Worth (Exluding primary residence)</CFormLabel>
            <CFormSelect
              disabled={loading}
              defaultValue={netWorthExcludingResidence}
              {...register('netWorthExcludingResidence')}
            >
              <option value="UNKNOWN">Unknown</option>
              <option value="LESS_THAN_200K">$0-$199,999</option>
              <option value="BETWEEN_200K_500K">$200,000-$499,999</option>
              <option value="BETWEEN_500K_1M">$500,000-$999,999</option>
              <option value="BETWEEN_1M_5M">$1,000,000-$4,999,999</option>
              <option value="BETWEEN_5M_15M">$5,000,000-$14,999,999</option>
              <option value="MORE_THAN_15M">$15,000,000+</option>
            </CFormSelect>
          </div>
        </div>
        <div className='row mt-2'>
          <div className='col-md-3'>
            <CFormLabel>Capital Allocation</CFormLabel>
            <CFormSelect
              disabled={loading}
              defaultValue={capitalAllocation}
              {...register('capitalAllocation')}
            >
              <option value="UNKNOWN">Unknown</option>
              <option value="LESS_THAN_25K">$0-$24,999</option>
              <option value="BETWEEN_25K_50K">$25,000-$49,999</option>
              <option value="BETWEEN_50K_100K">$50,000-$99,999</option>
              <option value="BETWEEN_100K_200K">$100,000-$199,999</option>
              <option value="BETWEEN_200K_500K">$200,000-$499,999</option>
              <option value="BETWEEN_500K_1M">$500,000-$999,999</option>
              <option value="BETWEEN_1M_5M">$1,000,000-$4,999,999</option>
              <option value="BETWEEN_5M_15M">$5,000,000-$14,999,999</option>
              <option value="MORE_THAN_15M">$15,000,000+</option>
            </CFormSelect>
          </div>
          <div className='col-md-3'>
            <CFormLabel>Sector Interest</CFormLabel>

            <Select
              defaultValue={sectorsDefaultValues}
              isMulti
              className="multiselect-input"
              options={sectorsOptions}
              name='sectors'
              ref={null}
              isDisabled={true}
            />
          </div>
        </div>
      </CCollapse>
    </div>
  )
}

export default FinancialProfile
