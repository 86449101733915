import React, { useCallback, useEffect, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import PageLayout from '../../layout/PageLayout'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import privateAPI from '../../APIs/private'
import { useHistory, useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner'
import SubmitButton from '../../components/SubmitButton'
import {
  CButton,
  CFormInput,
  CFormLabel,
  CFormTextarea,
} from '@coreui/react-pro'
import Select from '../assets/components/Select'
import { formatToPT } from '../../utils/formatUtils/utils'
import Checkbox from '../../components/Checkbox'
import { getCompanyData } from '../../utils/commonActions'
import ReactDatePicker from 'react-datepicker'
import { getCompanies } from '../../features/companiesSlice'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import { components } from 'react-select'

type ColumnType = {
  label: string
  name: string
  type: string
}

type LatestNews = {
  articleCategory: string
  articleTitle: string
  articleSnippet: string
  articleUrl: string
}

type IpoDetails = {
  latestNews: LatestNews[]
  lockUpPeriodEndDisplayDate: string
}

type CompanyIpoDetail = {
  canChangeDecision: boolean
  companyId: number
  companyIpoDataId: number
  createdAt: number
  ipoDetails: IpoDetails
  ipoJson: string
  isActive: boolean
  rowCount: number
}

type CompanyIpoDetailObject = {
  data: {
    columns: ColumnType[]
    rows: CompanyIpoDetail[]
  }
}

const getCompanyIpoData = async (
  id: string,
): Promise<CompanyIpoDetailObject> => {
  return await privateAPI.get(`/queries/company_ipo_datas/${id}`)
}

const saveCompanyIpoData = async (id: string, data: any) =>
  await privateAPI.post(`/queries/company_ipo_datas/${id}`, data)

const Option = (props: any) => {
  const {
    data: { id, value },
  } = props
  return (
    <components.Option {...props}>
      {value} (id: {id})
    </components.Option>
  )
}

const CompanyIPODataDetail = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const methods = useForm()
  const queryClient = useQueryClient()
  const { handleSubmit, reset, control, register, setValue, watch } = methods
  const { id }: any = useParams()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ipoDetails.latestNews',
  })

  const { data: companyIpoData, isInitialLoading: companyIpoLoading } =
    useQuery({
      queryKey: ['company_ipo_datas', id],
      queryFn: () => getCompanyIpoData(id),
      enabled: +id > 0,
    })

  const companyIpo: CompanyIpoDetail = companyIpoData?.data.rows?.[0] || {}
  const { companyId, createdAt } = companyIpo || {}

  const { data: companyData, isInitialLoading: companyDataLoading } = useQuery({
    queryKey: ['company_ipo_datas_company', companyIpo.companyId],
    queryFn: () => getCompanyData(companyIpo?.companyId),
    enabled: !!companyIpo?.companyId,
  })

  useEffect(() => {
    if (!!Object.keys(companyIpo)?.length) reset(companyIpo)
  }, [reset, companyIpo])

  const saveCompanyIpoDataMutation = useMutation({
    mutationFn: async (data: any) => {
      const obj = { ...data }
      // get lockUpPeriodEndDisplayDate from ipoDetails object
      const lockUpPeriodEndDisplayDate = watch(
        'ipoDetails.lockUpPeriodEndDisplayDate',
      )
      // format lockUpPeriodEndDisplayDate from string to timestamp
      if (typeof lockUpPeriodEndDisplayDate === 'number') {
        obj.ipoDetails.lockUpPeriodEndDisplayDate = lockUpPeriodEndDisplayDate
      } else {
        obj.ipoDetails.lockUpPeriodEndDisplayDate = Date.parse(
          lockUpPeriodEndDisplayDate,
        )
      }
      await saveCompanyIpoData(id, data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['company_ipo_datas', id],
      })
      if (+id === -1) {
        history.goBack()
      }
    },
  })

  const handleAppend = () => {
    append({
      articleUrl: '',
      articleTitle: '',
      articleSnippet: '',
      articleCategory: '',
    })
  }

  const fetchData = useCallback(async (e: string) => {
    const queryParams = {
      companyId: {},
      name: {},
      status: {},
      freeTextSearch: e,
      length: 10,
    }
    if (e.length > 0) {
      const response = await dispatch(
        getCompanies({ found: { queryParams: JSON.stringify(queryParams) } }),
      )
      if (response?.payload && response?.payload?.rows?.length) {
        const options: any = response?.payload?.rows?.map((a: any) => {
          return {
            id: a.companyId,
            value: a.name,
            label: a.name,
          }
        })
        return options
      }
    }
  }, [])

  const loadSuggestedOptions = useMemo(
    () =>
      debounce((inputValue: string, callback: any) => {
        fetchData(inputValue).then((options: any) => callback(options))
      }, 500),
    [fetchData],
  )

  const handleChange = (value: any) => {
    if (value) {
      setValue(
        'data',
        { id: value.id, label: value.value, value: value.value },
        { shouldDirty: true },
      )
      setValue('name', value.value, { shouldDirty: true })
      setValue('companyId', value.id, { shouldDirty: true })
    }
  }

  const loading = companyIpoLoading || companyDataLoading

  const isCreateIpo = +id === -1

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  const { name } = companyData || {}

  return (
    <PageLayout>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(saveCompanyIpoDataMutation.mutate)}>
          <div className="d-flex justify-content-between">
            <h3>
              {isCreateIpo
                ? 'Create Company Ipo Data'
                : `Company IPO Data No. ${id}`}
            </h3>
            <SubmitButton
              loading={saveCompanyIpoDataMutation.isLoading}
              text="Save"
            />
          </div>
          <div className="row g-3 mb-4">
            {!isCreateIpo && (
              <div className="col-md-3">
                <CFormLabel>Created At</CFormLabel>
                <CFormInput
                  disabled
                  value={formatToPT(createdAt, 'YYYY-MM-DD')}
                />
              </div>
            )}
            <div className="col-md-3">
              <CFormLabel>Lock up Period End Display Date At</CFormLabel>
              <Controller
                name="ipoDetails.lockUpPeriodEndDisplayDate"
                render={({ field: { onChange, value } }) => (
                  <ReactDatePicker
                    selected={value}
                    className="form-control"
                    onChange={onChange}
                    dateFormat="MM/dd/yyyy"
                    required={isCreateIpo}
                  />
                )}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Company</CFormLabel>
              <Select
                loadSuggestedOptions={loadSuggestedOptions}
                handleChange={handleChange}
                readOnly={!isCreateIpo}
                options={Option}
                watchValue={watch('name') ?? name}
                query="companies"
                id={watch('companyId') ?? companyId}
                ref={null}
              />
            </div>
            <div className="flex">
              <div className="col-md-3">
                <Checkbox
                  formLabel="Is Active"
                  registerValue="isActive"
                  btnLoading={false}
                />
              </div>
              <div className="col-md-3">
                <Checkbox
                  formLabel="Can Change Decision"
                  registerValue="canChangeDecision"
                  btnLoading={false}
                />
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <h4>News and Insights</h4>
                <CButton disabled={fields?.length === 3} onClick={handleAppend}>
                  Add
                </CButton>
              </div>
              {fields.map((field: any, index: number) => (
                <div key={field.id} className="d-flex row gx-3 gy-1 mb-5">
                  <div className="d-flex justify-content-between mt-2">
                    <h5>Article {index + 1}</h5>
                    <CButton
                      onClick={() => remove(index)}
                      className="bg-danger"
                    >
                      Delete
                    </CButton>
                  </div>
                  <div className="col-md-6">
                    <CFormLabel>Article URL</CFormLabel>
                    <CFormInput
                      {...register(`ipoDetails.latestNews.${index}.articleUrl`)}
                      disabled={saveCompanyIpoDataMutation.isLoading}
                    />
                  </div>
                  <div className="col-md-6">
                    <CFormLabel>Article Title</CFormLabel>
                    <CFormInput
                      {...register(
                        `ipoDetails.latestNews.${index}.articleTitle`,
                      )}
                      disabled={saveCompanyIpoDataMutation.isLoading}
                    />
                  </div>
                  <div className="col-md-6">
                    <CFormLabel>Article Snippet</CFormLabel>
                    <CFormTextarea
                      rows={1}
                      {...register(
                        `ipoDetails.latestNews.${index}.articleSnippet`,
                      )}
                      disabled={saveCompanyIpoDataMutation.isLoading}
                    />
                  </div>
                  <div className="col-md-6">
                    <CFormLabel>Article Category</CFormLabel>
                    <CFormInput
                      {...register(
                        `ipoDetails.latestNews.${index}.articleCategory`,
                      )}
                      disabled={saveCompanyIpoDataMutation.isLoading}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </form>
      </FormProvider>
    </PageLayout>
  )
}

export default CompanyIPODataDetail
